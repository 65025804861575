
@mixin alert-button-color($color) {
    .alert-button-#{$color} {
        background-color: var(--ion-color-#{$color});
        color: var(--ion-color-#{$color}-contrast);
    }
}


ion-alert {
    --ion-backdrop-color: var(--ion-color-primary);
    --ion-backdrop-opacity: 0.9;


    .alert-wrapper {

        border-radius: 12px !important;

        .alert-button-group {
            display: flex;
            justify-content: center;
            gap: 1rem;
            padding: 1rem;
        }

        .alert-button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            font-size: 14px;
            border-radius: var(--cwt-border-radius);
            text-transform: none;
            font-weight: 400;
            margin: 0px;
            padding-inline-start: 2em;
            padding-inline-end: 2em;

            &.alert-button-default {
                background-color: var(--ion-color-dark);
                color: var(--ion-color-dark-contrast);
            }
        }
        @include alert-button-color('primary');
        @include alert-button-color('danger');
    }
}

