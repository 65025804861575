@import "./styles/ion-variables.scss";
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./styles/fonts";
@import "./styles/colors";
@import "./styles/toast";
@import "./styles/button";
@import "./styles/modal";
@import "./styles/ripple";
@import "./styles/card";
@import "./styles/item";
@import "./styles/chips";
@import "./styles/datetime";
@import "./styles/alert";

ion-header::after { display: none; }
ion-footer::before { display: none; }

ion-toolbar{
    backdrop-filter: blur(3rem);
}

a {
    color: var(--ion-color-dark);
    &:hover { color: var(--ion-color-dark-tint); }
    &:active { color: var(--ion-color-dark-shade); }
    font-weight: bold;
}

.grecaptcha-badge {
    display: none;
}
.big {
    font-size: 120%;
}

.normal{
    font-size:normal;
}

.small {
    font-size: 80%;
}
.ant-form-vertical .ant-form-item {
    flex-wrap: nowrap;
}

.break-spaces{
    white-space: break-spaces;
}

.min-w-0 { min-width: 0; }
.max-w-100{
    max-width: 100%;
}
.text-truncate{
    display: inline;
}
.no-click {
    pointer-events: none;
}
.shadow{
    box-shadow: var(--cwt-shadow) !important;
}

.no-shadow {
    box-shadow: none;
    --box-shadow: none !important;
}

.cwt-map-marker-label {
    background: white;
    box-shadow: var(--cwt-black-shadow);
    padding: 0rem 0.4rem 0rem 0.4rem;
    transform: translateY(27px);
    border-radius: 8px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.page-width{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    padding:0 var(--ion-padding, 16px);
}


.line-clamp-2{
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}


.line-clamp-3{
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.deleted{
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
    filter:grayscale(1);
}