@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
:root {
  --cwt-fixed-page-width: 720px;
  --cwt-form-page-width: 478px;
  --cwt-border-radius: 8px;
  --ion-font-family: "Open Sans";
  --cwt-media-border: transparent;
  --cwt-media-border-hover: 1px solid #d8cdbe;
  --cwt-media-select-addon-color: #c3b5a1;
  --cwt-media-select-addon-color-hover:#d8cdbe;
  --cwt-media-select-addon-background: #F6F1EB;
  --cwt-media-border-radius-circle: 100em;
  --cwt-media-border-radius-default: 0px;
  --cwt-media-border-radius-round: 10px;
  --cwt-drop-shadow: 0px 0px 8px #000000;
  --cwt-shadow: #998f7f63 0px 0px 1rem;
  --cwt-black-shadow: rgba(0,0,0,0.2) 0px 0px 0.5rem;
  --cwt-toolbar-spacing: 10px;
  --ion-color-primary: #deceb7;
  --ion-color-primary-rgb: 222,206,183;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #c3b5a1;
  --ion-color-primary-tint: #e1d3be;
  --ion-color-secondary: #1f1f1f;
  --ion-color-secondary-rgb: 31,31,31;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #1b1b1b;
  --ion-color-secondary-tint: #353535;
  /** tertiary **/
  --ion-color-tertiary: #eee4d4;
  --ion-color-tertiary-rgb: 238,228,212;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #d1c9bb;
  --ion-color-tertiary-tint: #f0e7d8;
  /** dark **/
  --ion-color-dark: #2A2A2A;
  --ion-color-dark-rgb: 42,42,42;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #252525;
  --ion-color-dark-tint: #3f3f3f;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255,255,255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #FF5959;
  --ion-color-danger-rgb: 255,89,89;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #e04e4e;
  --ion-color-danger-tint: #ff6a6a;
  /** info **/
  --ion-color-info: #5fb0f3;
  --ion-color-info-rgb: 95, 176, 243;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0,0,0;
  --ion-color-info-shade: #3784c4;
  --ion-color-info-tint: #b5dcfc;
  --ion-background-color: #fdfcf9;
  --ion-background-color-rgb: 253, 252, 249;
  --ion-color-gradient-gold: linear-gradient(315deg, #dac9ae 0%, #f2e6d8 50%, #d3beac 100%);
  --ion-color-gradient-gold-rgb: 238,228,212;
  --ion-color-gradient-gold-contrast: #000000;
  --ion-color-gradient-gold-contrast-rgb: 0,0,0;
  --ion-color-gradient-gold-shade: #dac9ae;
  --ion-color-gradient-gold-tint: #f2e6d8;
  --ion-color-gradient-light: linear-gradient(315deg, #e3d2bf 0%, #f2e6d8 50%, #e1d0b9 100%);
  --ion-color-gradient-light-rgb: 238,228,212;
  --ion-color-gradient-light-contrast: #000000;
  --ion-color-gradient-light-contrast-rgb: 0,0,0;
  --ion-color-gradient-light-shade: #e3d2bf;
  --ion-color-gradient-light-tint: #ede5d7;
  --ion-color-transparent: transparent;
  --ion-color-transparent-rgb: 255,255,255;
  --ion-color-transparent-contrast: #000000;
  --ion-color-transparent-contrast-rgb: 0,0,0;
  --ion-color-transparent-shade: transparent;
  --ion-color-transparent-tint: transparent;
  --ion-color-brand: #ffffff;
  --ion-color-brand-rgb: 255,255,255;
  --ion-color-brand-shade: #e0e0e0;
  --ion-color-brand-tint: #ffffff;
  --ion-color-google-contrast: #DB4437;
  --ion-color-google-contrast-rgb: 219,68,55;
  --ion-color-apple-contrast: #000000;
  --ion-color-apple-contrast-rgb: 0,0,0;
  --ion-color-bg: #fdfcf9;
  --ion-color-bg-rgb: 253, 252, 249;
  --ion-color-bg-contrast: #000000;
  --ion-color-bg-contrast-rgb: 0,0,0;
  --ion-color-bg-shade: #fdfcf9;
  --ion-color-bg-tint: #fdfcf9;
  --ion-color-input-bg: #f6f1eb;
  --ion-color-input-bg-rgb: 246, 241, 235;
  --ion-color-input-bg-contrast: #000000;
  --ion-color-input-bg-contrast-rgb: 0,0,0;
  --ion-color-input-bg-shade: #f6f1eb;
  --ion-color-input-bg-tint: #f6f1eb;
}

/* Basic CSS for apps built with Ionic */
/* Optional CSS utils that can be commented out */
@font-face {
  font-family: "ferrara";
  src: url("/assets/fonts/ferrara_regular.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ferrara";
  src: url("/assets/fonts/ferrara_bold.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/OpenSans-Light.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/OpenSans-Medium.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/OpenSans-SemiBold.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/OpenSans-ExtraBold.ttf");
}
@font-face {
  font-family: "Ferrara";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/BodoniFerrara-Light.otf");
}
@font-face {
  font-family: "Ferrara";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/BodoniFerrara-Regular.otf");
}
@font-face {
  font-family: "Ferrara";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/BodoniFerrara-Medium.otf");
}
@font-face {
  font-family: "Ferrara";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/BodoniFerrara-SemiBold.otf");
}
@font-face {
  font-family: "Ferrara";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/BodoniFerrara-Bold.otf");
}
@font-face {
  font-family: "Ferrara";
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/BodoniFerrara-ExtraBold.otf");
}
.ion-color-gradient-gold {
  --ion-color-base: var(--ion-color-gradient-gold);
  --ion-color-base-rgb: var(--ion-color-gradient-gold-rgb);
  --ion-color-contrast: var(--ion-color-gradient-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gradient-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-gold-shade);
  --ion-color-tint: var(--ion-color-gradient-gold-tint);
}

.ion-color-gradient-light {
  --ion-color-base: var(--ion-color-gradient-light);
  --ion-color-base-rgb: var(--ion-color-gradient-light-rgb);
  --ion-color-contrast: var(--ion-color-gradient-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gradient-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-light-shade);
  --ion-color-tint: var(--ion-color-gradient-light-tint);
}

.ion-color-transparent {
  --ion-color-base: var(--ion-color-transparent);
  --ion-color-base-rgb: var(--ion-color-transparent-rgb);
  --ion-color-contrast: var(--ion-color-transparent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-transparent-contrast-rgb);
  --ion-color-shade: var(--ion-color-transparent-shade);
  --ion-color-tint: var(--ion-color-transparent-tint);
}

.ion-color-bg {
  --ion-color-base: var(--ion-color-bg);
  --ion-color-base-rgb: var(--ion-color-bg-rgb);
  --ion-color-contrast: var(--ion-color-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-bg-shade);
  --ion-color-tint: var(--ion-color-bg-tint);
}

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

.ion-color-input-bg {
  --ion-color-base: var(--ion-color-input-bg);
  --ion-color-base-rgb: var(--ion-color-input-bg-rgb);
  --ion-color-contrast: var(--ion-color-input-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-input-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-input-bg-shade);
  --ion-color-tint: var(--ion-color-input-bg-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-brand);
  --ion-color-base-rgb: var(--ion-color-brand-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-shade);
  --ion-color-tint: var(--ion-color-brand-tint);
}

.ion-color-apple {
  --ion-color-base: var(--ion-color-brand);
  --ion-color-base-rgb: var(--ion-color-brand-rgb);
  --ion-color-contrast: var(--ion-color-apple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-apple-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-shade);
  --ion-color-tint: var(--ion-color-brand-tint);
}

ion-toast {
  --background: var(--ion-color-base);
  --color: var(--ion-color-contrast);
  --border-color: var(--ion-color-tint);
  --border-radius: 12px;
  --border-style: solid;
  --border-width: 1px;
  --box-shadow: var(--cwt-shadow);
  font-weight: bold;
}

ion-button {
  --border-width: 1px;
  --box-shadow: none;
  --padding-start: 2em;
  --padding-end: 2em;
  --border-radius: var(--cwt-border-radius);
  text-transform: none;
  font-weight: 400;
  margin: 0px;
  min-width: 27px;
}
ion-button.button-round {
  --border-radius: 10rem;
}
ion-button.button-solid {
  --box-shadow: none;
}
ion-button.button-outline::part(native) {
  background: var(--ion-background-color, transparent);
}
ion-button.button-has-icon-only {
  --padding-start: 4px !important;
  --padding-end: 4px !important;
  --padding-top: 4px !important;
  --padding-bottom: 4px !important;
  width: 36px;
  height: 36px;
}
ion-button.button-has-icon-only.button-small {
  width: 2.1em;
  height: 2.1em;
}
ion-button.button-has-icon-only.button-large {
  width: 2.8em;
  height: 2.8em;
}
ion-button.button-has-icon-only.button-huge {
  width: 64px;
  height: 64px;
}
ion-button.button-has-icon-only.button-huge ion-icon[slot=icon-only] {
  font-size: 56px;
}

ion-buttons ion-button {
  --padding-start: 12px;
  --padding-end: 12px;
}

ion-modal {
  --border-radius: 12px !important;
  --background: rgb(var(--ion-background-color-rgb, 255, 255, 255));
  --ion-backdrop-color:var(--ion-color-primary);
  --backdrop-opacity:0.9;
}
ion-modal.cwt-dynamic-height-modal {
  --ion-safe-area-top: 0px;
  --ion-safe-area-bottom: 0px;
  --ion-safe-area-left: 0px;
  --ion-safe-area-right: 0px;
  --height: auto;
  --width: 576px;
  --max-width: calc(100vw - 2rem);
}
ion-modal.cwt-dynamic-height-modal .ion-page {
  position: relative;
  display: block;
}
ion-modal.cwt-clear-modal {
  --background: transparent;
  --box-shadow: none;
  --overflow: visible;
}
ion-modal.cwt-clear-modal ion-header, ion-modal.cwt-clear-modal ion-toolbar, ion-modal.cwt-clear-modal ion-footer {
  --background: transparent;
  backdrop-filter: none !important;
}
ion-modal.cwt-clear-modal .ion-page {
  overflow: visible;
}
ion-modal.cwt-clear-dynamic-modal {
  --ion-safe-area-top: 0px;
  --ion-safe-area-bottom: 0px;
  --ion-safe-area-left: 0px;
  --ion-safe-area-right: 0px;
  --height: auto;
  --width: 576px;
  --max-width: calc(100vw - 2rem);
  --border-radius:unset !important;
  --background: transparent;
  --box-shadow: none;
  --overflow: auto;
  --max-height:100%;
  --max-width:100vw;
  --padding:1rem;
}
ion-modal.cwt-clear-dynamic-modal ion-header, ion-modal.cwt-clear-dynamic-modal ion-toolbar, ion-modal.cwt-clear-dynamic-modal ion-footer {
  --background: transparent;
  backdrop-filter: none !important;
}
ion-modal.cwt-clear-dynamic-modal .ion-page {
  position: relative;
  display: block;
  contain: content;
}
@media (max-width: 767px) {
  ion-modal {
    --border-radius: unset !important;
  }
}

ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
  --backdrop-opacity: 0.9;
}

ion-ripple-effect.custom_color {
  color: rgb(194, 143, 67);
}

ion-card {
  margin: 0;
  border-radius: 1rem;
  box-shadow: var(--cwt-shadow);
}
ion-card::part(native) {
  border: none !important;
}
ion-card.corner-card {
  border-radius: 1rem 0;
}

ion-item {
  --background: transparent !important;
  font-size: 14px;
}

ion-chip:not(.chip-outline).ion-color {
  background-color: rgba(var(--ion-color-base-rgb), 0.5);
}

ion-chip[size=xsmall] {
  height: 18px;
  padding: 0 0.6rem;
  font-size: 10px;
  margin: 0;
}
ion-chip[size=small] {
  padding: 7px 12px;
  height: auto;
  font-size: 13px;
  line-height: 1;
  margin: 0;
}

ion-datetime {
  max-width: 100%;
  --background:unset;
}
ion-datetime .calendar-next-prev {
  position: absolute;
}

ion-alert {
  --ion-backdrop-color: var(--ion-color-primary);
  --ion-backdrop-opacity: 0.9;
}
ion-alert .alert-wrapper {
  border-radius: 12px !important;
}
ion-alert .alert-wrapper .alert-button-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}
ion-alert .alert-wrapper .alert-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  font-size: 14px;
  border-radius: var(--cwt-border-radius);
  text-transform: none;
  font-weight: 400;
  margin: 0px;
  padding-inline-start: 2em;
  padding-inline-end: 2em;
}
ion-alert .alert-wrapper .alert-button.alert-button-default {
  background-color: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
}
ion-alert .alert-wrapper .alert-button-primary {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
ion-alert .alert-wrapper .alert-button-danger {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

ion-header::after {
  display: none;
}

ion-footer::before {
  display: none;
}

ion-toolbar {
  backdrop-filter: blur(3rem);
}

a {
  color: var(--ion-color-dark);
  font-weight: bold;
}
a:hover {
  color: var(--ion-color-dark-tint);
}
a:active {
  color: var(--ion-color-dark-shade);
}

.grecaptcha-badge {
  display: none;
}

.big {
  font-size: 120%;
}

.normal {
  font-size: normal;
}

.small {
  font-size: 80%;
}

.ant-form-vertical .ant-form-item {
  flex-wrap: nowrap;
}

.break-spaces {
  white-space: break-spaces;
}

.min-w-0 {
  min-width: 0;
}

.max-w-100 {
  max-width: 100%;
}

.text-truncate {
  display: inline;
}

.no-click {
  pointer-events: none;
}

.shadow {
  box-shadow: var(--cwt-shadow) !important;
}

.no-shadow {
  box-shadow: none;
  --box-shadow: none !important;
}

.cwt-map-marker-label {
  background: white;
  box-shadow: var(--cwt-black-shadow);
  padding: 0rem 0.4rem 0rem 0.4rem;
  transform: translateY(27px);
  border-radius: 8px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.page-width {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
  padding: 0 var(--ion-padding, 16px);
}

.line-clamp-2 {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.line-clamp-3 {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.deleted {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
  filter: grayscale(1);
}