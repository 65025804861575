
@mixin ion-color($name) {
    .ion-color-#{$name} {
        --ion-color-base: var(--ion-color-#{$name});
        --ion-color-base-rgb: var(--ion-color-#{$name}-rgb);
        --ion-color-contrast: var(--ion-color-#{$name}-contrast);
        --ion-color-contrast-rgb: var(--ion-color-#{$name}-contrast-rgb);
        --ion-color-shade: var(--ion-color-#{$name}-shade);
        --ion-color-tint: var(--ion-color-#{$name}-tint);
    }
}

@mixin ion-color-brand($name) {
    .ion-color-#{$name} {
        --ion-color-base: var(--ion-color-brand);
        --ion-color-base-rgb: var(--ion-color-brand-rgb);
        --ion-color-contrast: var(--ion-color-#{$name}-contrast);
        --ion-color-contrast-rgb: var(--ion-color-#{$name}-contrast-rgb);
        --ion-color-shade: var(--ion-color-brand-shade);
        --ion-color-tint: var(--ion-color-brand-tint);
    }
}

@include ion-color('gradient-gold');
@include ion-color('gradient-light');
@include ion-color('transparent');
@include ion-color('bg');
@include ion-color('info');
@include ion-color('input-bg');

@include ion-color-brand('google');
@include ion-color-brand('apple');