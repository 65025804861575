@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "./styles/nz-variables.less";

@font-family: 'Open Sans';

.clickable {
    cursor: pointer;
}

.ant-space.ant-space-vertical {
    display: flex;
}

.ant-result-title {
    line-height: 1.25;
    margin-bottom: 1rem;
    font-weight: 700;
}