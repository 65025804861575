

ion-datetime{
    max-width:100%;
        --background:unset;

        .calendar-next-prev{
            position:absolute;
        }
    
}
