
@font-face {
    font-family: 'ferrara';
    src: url('/assets/fonts/ferrara_regular.otf');
    font-weight: normal;
    font-style: normal;
    // letter-spacing: 2px;
}

@font-face {
    font-family: 'ferrara';
    src: url('/assets/fonts/ferrara_bold.otf');
    font-weight: bold;
    font-style: normal;
    // letter-spacing: 2px;
}

$font-weights-names: (
    "300": "Light",
    "400": "Regular",
    "500": "Medium",
    "600": "SemiBold", 
    "700": "Bold",
    "800": "ExtraBold"
);
$font-styles-names: (
    "italic": "Italic",
    "normal": "",
);

@mixin font-face( $font-name, $font-path, $font-weight: 400, $font-style: normal) {
  @font-face {
    font-family: $font-name;
    font-style : $font-style;
    font-weight: $font-weight;
    src: url('#{$font-path}');
  }
}

@mixin font-face-variants($font-name, $font-path, $ext) {
    @include font-face($font-name, '#{$font-path}-Light.#{$ext}', 300, normal);
    @include font-face($font-name, '#{$font-path}-Regular.#{$ext}', 400, normal);
    @include font-face($font-name, '#{$font-path}-Medium.#{$ext}', 500, normal);
    @include font-face($font-name, '#{$font-path}-SemiBold.#{$ext}', 600, normal);
    @include font-face($font-name, '#{$font-path}-Bold.#{$ext}', 700, normal);
    @include font-face($font-name, '#{$font-path}-ExtraBold.#{$ext}', 800, normal);
}

@include font-face-variants('Open Sans', '/assets/fonts/OpenSans', 'ttf');
@include font-face-variants('Ferrara', '/assets/fonts/BodoniFerrara', 'otf');