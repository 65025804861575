// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
    --cwt-fixed-page-width: 720px;
    --cwt-form-page-width: 478px;
    --cwt-border-radius: 8px;

    --ion-font-family: 'Open Sans';

    --cwt-media-border: transparent;
    --cwt-media-border-hover: 1px solid #d8cdbe;

    --cwt-media-select-addon-color: #c3b5a1;
    --cwt-media-select-addon-color-hover:#d8cdbe;
    
    --cwt-media-select-addon-background: #F6F1EB;

    --cwt-media-border-radius-circle: 100em;
    --cwt-media-border-radius-default: 0px;
    --cwt-media-border-radius-round: 10px;

    --cwt-drop-shadow: 0px 0px 8px #000000;
    --cwt-shadow: #998f7f63 0px 0px 1rem;
    --cwt-black-shadow: rgba(0,0,0,0.2) 0px 0px 0.5rem;
    --cwt-toolbar-spacing: 10px;

	--ion-color-primary: #deceb7;
	--ion-color-primary-rgb: 222,206,183;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #c3b5a1;
	--ion-color-primary-tint: #e1d3be;

	--ion-color-secondary: #1f1f1f;
	--ion-color-secondary-rgb: 31,31,31;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #1b1b1b;
	--ion-color-secondary-tint: #353535;

    /** tertiary **/
	--ion-color-tertiary: #eee4d4;
	--ion-color-tertiary-rgb: 238,228,212;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #d1c9bb;
	--ion-color-tertiary-tint: #f0e7d8;

    /** dark **/
	--ion-color-dark: #2A2A2A;
	--ion-color-dark-rgb: 42,42,42;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #252525;
	--ion-color-dark-tint: #3f3f3f;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
	--ion-color-light: #ffffff;
	--ion-color-light-rgb: 255,255,255;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #e0e0e0;
	--ion-color-light-tint: #ffffff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
	--ion-color-danger: #FF5959;
	--ion-color-danger-rgb: 255,89,89;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #e04e4e;
	--ion-color-danger-tint: #ff6a6a;

    /** info **/
	--ion-color-info: #5fb0f3;
	--ion-color-info-rgb: 95, 176, 243;
	--ion-color-info-contrast: #000000;
	--ion-color-info-contrast-rgb: 0,0,0;
	--ion-color-info-shade: #3784c4;
	--ion-color-info-tint: #b5dcfc;
    
    --ion-background-color: #fdfcf9;
    --ion-background-color-rgb: 253, 252, 249;
    
	--ion-color-gradient-gold: linear-gradient(315deg, #dac9ae 0%, #f2e6d8 50%, #d3beac 100%);
	--ion-color-gradient-gold-rgb: 238,228,212;
	--ion-color-gradient-gold-contrast: #000000;
	--ion-color-gradient-gold-contrast-rgb: 0,0,0;
	--ion-color-gradient-gold-shade: #dac9ae;
	--ion-color-gradient-gold-tint: #f2e6d8;
	
    --ion-color-gradient-light: linear-gradient(315deg, #e3d2bf 0%, #f2e6d8 50%, #e1d0b9 100%);
	--ion-color-gradient-light-rgb: 238,228,212;
	--ion-color-gradient-light-contrast: #000000;
	--ion-color-gradient-light-contrast-rgb: 0,0,0;
	--ion-color-gradient-light-shade: #e3d2bf;
	--ion-color-gradient-light-tint: #ede5d7;
    
    --ion-color-transparent: transparent;
    --ion-color-transparent-rgb: 255,255,255;
	--ion-color-transparent-contrast: #000000;
	--ion-color-transparent-contrast-rgb: 0,0,0;
	--ion-color-transparent-shade: transparent;
	--ion-color-transparent-tint: transparent;

    --ion-color-brand: #ffffff;
    --ion-color-brand-rgb: 255,255,255;
    --ion-color-brand-shade: #e0e0e0;
    --ion-color-brand-tint: #ffffff;
    --ion-color-google-contrast: #DB4437;
    --ion-color-google-contrast-rgb: 219,68,55;
    --ion-color-apple-contrast: #000000;
    --ion-color-apple-contrast-rgb: 0,0,0;

	--ion-color-bg: #fdfcf9;
    --ion-color-bg-rgb: 253, 252, 249;
	--ion-color-bg-contrast: #000000;
	--ion-color-bg-contrast-rgb: 0,0,0;
	--ion-color-bg-shade: #fdfcf9;
	--ion-color-bg-tint: #fdfcf9;


	--ion-color-input-bg: #f6f1eb;
    --ion-color-input-bg-rgb: 246, 241, 235;
	--ion-color-input-bg-contrast: #000000;
	--ion-color-input-bg-contrast-rgb: 0,0,0;
	--ion-color-input-bg-shade: #f6f1eb;
	--ion-color-input-bg-tint: #f6f1eb;

}
