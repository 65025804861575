ion-card {
    margin: 0;
    border-radius: 1rem;
    box-shadow: var(--cwt-shadow);
    &::part(native) {
        border: none !important;
    }
    
    &.corner-card {
        border-radius: 1rem 0;
    }
}