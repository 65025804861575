ion-button {
    --border-width: 1px;
    --box-shadow: none;
    --padding-start: 2em;
    --padding-end: 2em;
    --border-radius: var(--cwt-border-radius);
    text-transform: none;
    font-weight: 400;
    margin: 0px;
    min-width: 27px;

    &.button-round {
        --border-radius: 10rem;
    }

    &.button-solid {
        --box-shadow: none;
    }

    &.button-outline {
        &::part(native) {
            background: var(--ion-background-color, transparent);
        }
    }

    &.button-has-icon-only {
        --padding-start: 4px !important;
        --padding-end: 4px !important;
        --padding-top: 4px !important;
        --padding-bottom: 4px !important;
        width: 36px;
        height: 36px;

        &.button-small {
            width: 2.1em;
            height: 2.1em;
        }

        &.button-large {
            width: 2.8em;
            height: 2.8em;
        }

        &.button-huge {
            width: 64px;
            height: 64px;

            ion-icon[slot="icon-only"] {
                font-size: 56px;
            }
        }
    }
}

ion-buttons ion-button {
    --padding-start: 12px;
    --padding-end: 12px;
}
