
ion-modal {
    --border-radius: 12px !important;
    --background: rgb(var(--ion-background-color-rgb, 255, 255, 255));
    --ion-backdrop-color:var(--ion-color-primary);
    --backdrop-opacity:0.9;





    &.cwt-dynamic-height-modal {
        --ion-safe-area-top: 0px;
        --ion-safe-area-bottom: 0px;
        --ion-safe-area-left: 0px;
        --ion-safe-area-right: 0px;
        
        --height: auto;
        --width: 576px;
        --max-width: calc(100vw - 2rem);

        .ion-page {
            position: relative;
            display: block;
        }
    }

    &.cwt-clear-modal {
        --background: transparent;
        --box-shadow: none;
        --overflow: visible;
        
        ion-header, ion-toolbar, ion-footer {
            --background: transparent;
            backdrop-filter: none !important;
        }

        .ion-page {
            overflow: visible;
        }
    }

    &.cwt-clear-dynamic-modal{
        --ion-safe-area-top: 0px;
        --ion-safe-area-bottom: 0px;
        --ion-safe-area-left: 0px;
        --ion-safe-area-right: 0px;
        
        --height: auto;
        --width: 576px;
        --max-width: calc(100vw - 2rem);

        --border-radius:unset !important;
        --background: transparent;
        --box-shadow: none;
        --overflow: auto;

        --max-height:100%;
        --max-width:100vw;
        --padding:1rem;

        ion-header, ion-toolbar, ion-footer {
            --background: transparent;
            backdrop-filter: none !important;
        }
        

        .ion-page {
            position: relative;
            display: block;
            contain: content;

            
         
        }
    }

    @media (max-width: 767px) {
        --border-radius: unset !important;
    }

  

}

ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default{
    --backdrop-opacity: 0.9;
}