ion-chip:not(.chip-outline).ion-color {
    background-color: rgba(var(--ion-color-base-rgb), 0.5);
    // color:var(--ion-color-dark);
}
ion-chip{
    &[size='xsmall']{
        height: 18px;
        padding:0 0.6rem;
        font-size: 10px;
        margin:0;
    }

   
    &[size='small'] {
        padding: 7px 12px;
        height: auto;
        font-size: 13px;
        line-height: 1;
        margin:0;
    }
    
}